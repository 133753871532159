import useFetch from "../parts/useFetch";
import { useParams } from "react-router-dom";
import CenterService from "./CenterService";
import CenterServicesList from "../parts/CenterServicesList";
import CenterServiceBookingInfo from "./CenterServiceBookingInfo";
import CentersVipCarousel from "../parts/CentersVipCarousel";
import Titleize from "../../utilities/Titleize";

const Service = () => {

  const { slug } = useParams();

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/services/${slug}`;

  const {data, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  return(
    <>
      <div className="single-service-content single-service-content-all single-service-content-info pt-5 pb-3">
        <div className="container p-0 mb-4">
          <div className="row">
            <div className="col-lg-8">
              <div className="container container-top container-top-2 slgo-service-card container-h-100 slgo-mobile-w-94 py-5 px-4 px-sm-5 mt-0 mt-md-5 mb-4 mb-md-5">
                <div className="row g-5">
                  <div className="col-md-12 pb-4">
                    <h1 className="h2 slgo-title slgo-text-capitalized font-weight-bold">{Titleize(data?.data.service_name)}</h1>
                    {(data?.data.description) &&
                      <div className="col-md-12 mt-4">
                        {/*<h6 className="mb-1"><b>Descrizione</b></h6>*/}
                        <p>{data?.data.description}</p>
                      </div>
                    }
                    <p className="pb-2 pt-5">Nome Nazionale: <br/>
                      <b>{data?.data.category_name}</b>
                    </p>
                    <p className="pb-2">Nome presso il Centro Medico: <br/>
                      <b>{data?.data.service_name}</b>
                    </p>
                    <p className="pb-2">Categoria: <br/>
                      <b>{data?.data.service_type}</b>
                    </p>
                    <p className="pb-2">Tipo di Prestazione: <br/>
                      <b>{data?.data.activity_type}</b>
                    </p>
                  </div>
                  {/*<div className="single-center-contacts col-md-12 mt-0 pb-4">
                    <p className="mb-3 pe-3"><a href="#prenota-ora" rel="noreferrer" className="btn btn-primary btn-no-arrow slgo-btn-single-center slgo-btn-single-center-options"><b>Opzioni di prenotazione</b></a></p>
                  </div>*/}
                </div>
              </div>
            </div>
            <CenterService centerId={data?.data.center_id} serviceName={data?.data.service_name} />
          </div>
        </div>
        <CenterServiceBookingInfo centerId={data?.data.center_id} />
      </div>
      <CentersVipCarousel id={data?.data.id} caller='service' />
      <CenterServicesList centerId={data?.data.center_id}/>
    </>
  );

}

export default Service;