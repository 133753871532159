import SignUpEmailFormFields from "./SignUpEmailFormFields";
import SignUpEmailFormRadio from "./SignUpEmailFormRadio";
import { useState, createContext, useEffect } from "react";
import axios from "axios";
import GetKeyByValue from "../../utilities/GetKeyByValue";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

export const EmailFormContext = createContext();

const SignUpEmailForm = ({ planType }) => {

  const [disabled, setDisabled] = useState(true);

  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [centerName, setCenterName] = useState('')
  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const [radioValue, setRadioValue] = useState({
    "free": true,
    "premium": false,
    "custom": false
  })

  useEffect(() => {
    switch (planType) {
      case 'PREMIUM':
        setRadioValue({
          free: false,
          premium: true,
          custom: false,
        });
        break;
      case 'CUSTOM':
        setRadioValue({
          free: false,
          premium: false,
          custom: true,
        });
        break;
      default:
        setRadioValue({
          free: true,
          premium: false,
          custom: false,
        });
        break;
    }
  }, [planType]);

  const contextValue = {
    name,
    setName,
    surname,
    setSurname,
    email,
    setEmail,
    centerName,
    setCenterName,
    message,
    setMessage,
    radioValue,
    setRadioValue
  };

  const sendEmail = async () => {

    if (!name.trim() || !surname.trim() || !email.trim() || !centerName.trim()) {
      setSuccessMsg('I campi Nome, Cognome, Indirizzo e-mail e Nome del Centro Medico sono obbligatori.');
      return;
    }
    const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/send_email`

    const body = {
      "plan": GetKeyByValue(radioValue, true),
      "name": name,
      "surname": surname,
      "email": email,
      "center_name": centerName,
      "message": message
    }
  
    await axios.post(url, body)
      .then((response) => {
        if (response?.status === 200) {
          setSuccessMsg('Richiesta inviata con successo.')
          setName('')
          setSurname('')
          setEmail('')
          setCenterName('')
          setMessage('')
        } else {
          setSuccessMsg('Purtroppo si è verificato un errore, ritenta tra qualche minuto.')
        }
      })
      .catch((err) => {
        setSuccessMsg('Purtroppo si è verificato un errore, ritenta tra qualche minuto.')
        console.log(err);
      });
  }

  const handleVerify = () => {
    setDisabled(false);
  }

  return(
    <>
      <div className="signup-form-content container p-5 pb-4">
        <div className="row row-flex">
          <EmailFormContext.Provider value={contextValue}>
            <SignUpEmailFormRadio />
            <SignUpEmailFormFields />
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}>
              <GoogleReCaptcha onVerify={handleVerify} />
            </GoogleReCaptchaProvider>
            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary float-end slgo-light-text"
                disabled={disabled}
                onClick={() => sendEmail()}>Invia richiesta
              </button>
            </div>
            <div className="col-md-12">
              <p className="mb-0 mt-3">{successMsg}</p>
            </div>
          </EmailFormContext.Provider>
        </div>
      </div>
    </>
  );
}

export default SignUpEmailForm;

