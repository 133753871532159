import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";
import IsBot from "../../utilities/IsBot";
import useFetch from "./useFetch";
import { Link } from "react-router-dom";

import React, { useState,useEffect } from 'react';

const CentersVipCarousel = ({ id, caller }) => {
  const [isBot, setIsBot] = useState("true");
  
  const apiPath = caller === 'service' ? 'adv_services_near_service' : 'adv_centers_near_center';
  const title = caller === 'service' ? 'Centri medici e ambulatori vicini con la stessa prestazione sanitaria' : 'Centri medici e ambulatori vicini';

  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/${apiPath}/${id}?is_bot=${isBot}`;

  const { data, loading, error } = useFetch(url);

  useEffect(() => {
    setIsBot(IsBot());
  }, []);

  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) return `Errore: ${error.message}`;
  if (!data) {
    console.log(`No data returned from GET ${url}`);
  }

  return (
    <>
      <div className="related-centers slgo-single-center-related-centers mb-5">
        <div className="container pt-0 pb-5">
          <div className="row">
            <div className="col-md-12 pb-3 px-3 px-sm-0">
                <h4 className="slgo-light-text font-weight-bold">{title}</h4>
            </div>
          </div>
          <div className="row row-grid px-3 px-sm-0">
            {data?.data.map((center) => (
              <div className="slgo-single-related-center" key={center.slug}>{/* col-md-2 flex-fill */}
                <div className="slgo-img-single-center-related-centers p-md-2">
                  <a href={`/centro-medico/${center.slug}`} className="slgo-center-img d-block mb-md-3">
                    <img src={center.logo} width="240" height="194" className="slgo-img-h-auto" alt={Capitalize(center.center_name)} />
                  </a>
                  <h6 className="slgo-light-text p-0 my-0 mx-md-0 mx-2">
                    <a href={`/centro-medico/${center.slug}`}>{FormatCenterName(center.center_name)}</a>
                  </h6>
                </div>
                <div className="slgo-contact-related-center p-md-2">
                  { center.phone_number &&
                    <a href={`tel://${center.phone_number}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-phone"></a>
                  }
                  { center.emails &&
                    <a href={`mailto:${center.emails}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-email"></a>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default CentersVipCarousel;
