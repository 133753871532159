import { useParams } from "react-router-dom";
import ReMap from "../parts/ReMap";
import useFetch from "../parts/useFetch";
import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";
import Titleize from "../../utilities/Titleize";
import CenterServicesList from "../parts/CenterServicesList";
import CtaCooperate from "../parts/CtaCooperate";
import CenterSeo from "./CenterSeo";
import CentersVipCarousel from "../parts/CentersVipCarousel";
import CleanUpUrl from "../../utilities/CleanUpUrl";

const Center = ({ slug, page }) => {

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers/${slug}`;

  const {data, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }
  if (error) console.log(error);

  return(
    <>
      <CenterSeo 
        centerName={data?.data.center_name}
        province={data?.data.geo_data.province_name}
        locality={data?.data.geo_data.locality_name}
      />  
      <div className="single-center-content single-center-content-info py-5">
        <div className="container container-top single-center-content-info-maps slgo-mobile-w-94 p-3 p-sm-5 mt-0 mt-md-5 mb-4 mb-md-5">
          <div className="row flex-row-reverse g-5">
            <div className="col-md-6">
              <div className="slgo-img-single-center" href="#" style={{backgroundImage: `url(${data?.data.logo})`}}>
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="slgo-pre-title font-weight-bold mb-2">Centro Medico</h4>
              <h1 className="h2 slgo-title font-weight-bold mb-4">{FormatCenterName(data?.data.center_name)}</h1>
              <p>Indirizzo: <b>{Titleize(data?.data.geo_data.street_name)}, {data?.data.geo_data.street_number}        
              </b></p>
              <p>CAP: <b>{data?.data.geo_data.postal_code}</b></p>
              <p>Città: <b>{Capitalize(data?.data.geo_data.locality_name)}</b></p>
              <p>Provincia: <b>{Capitalize(data?.data.geo_data.province_name)} ({data?.data.geo_data.province_shortname.toUpperCase()})</b></p>
              <p>Regione: <b>{Capitalize(data?.data.geo_data.region_name)}</b></p>
              <div className="single-center-contacts mt-4 mb-2">
                {(data?.data.phone_number) &&
                  <p className="mb-3"><a href={"tel://" + data?.data.phone_number} className="btn btn-primary btn-no-arrow slgo-btn-single-center slgo-btn-phone"><b>Telefona</b></a></p>
                }
                {(data?.data.emails) &&
                  <p className="mb-3"><a href={"mailto:" + data?.data.emails} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-no-arrow slgo-btn-single-center slgo-btn-email"><b>Scrivi</b></a></p>
                }
                {(data?.data.web_site_url) &&
                  <p className="mb-3"><a href={data?.data.web_site_url} target="_blank" rel="noreferrer" className="btn btn-primary btn-no-arrow slgo-btn-single-center slgo-btn-website"><b>Sito web</b></a></p>
                }
              </div>
            </div>
          </div>
          <div className="spacer-single-center border-top mt-5 pt-0 pt-md-5"></div>
          <div className="row g-5">
            <div className="col-md-12 px-2 px-sm-4">
            <ReMap markersLatLong={[{latitude: data?.data.geo_data.latitude, longitude: data?.data.geo_data.longitude}]} />
            </div>
          {(data?.data.description) &&
            <div className="col-md-12 mt-4">
              <h6 className="mb-1"><b>Descrizione</b></h6>
              <p>{data?.data.description}</p>
            </div>
          }
          {(data?.data.add_info) &&
            <div className="col-md-12 mt-4">
              <h6 className="mb-1"><b>Altro</b></h6>
              <p>{data?.data.add_info}</p>
            </div>
          }
          </div>
        </div>
      </div>
      <CentersVipCarousel id={data?.data.id} caller='center'/>
      <CenterServicesList centerId={data?.data.id} page={page}/>
      <CtaCooperate centerId={data?.data.id}/>
    </>
  );
}

export default Center;