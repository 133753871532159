import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";

const CategoryInput = ({
  categoryName,
  setCategoryName,
  setCategoryId,
  formattedAddress,
  categoryId,
  setDisabled,
}) => {
  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/categories?do_not_page=true`;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [inputValue, setInputValue] = useState(categoryName);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const categoryOptions = data.data.map((category) => ({
          value: category.id,
          label: category.category_name,
        }));
        setCategories(categoryOptions);
        setFilteredCategories(categoryOptions);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [url]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = categories.filter((category) =>
      category.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCategories(filtered);
  };

  const handleSelect = (value, item) => {
    setCategoryName(item.label);
    setCategoryId(item.value);
    setInputValue(item.label);
  };

  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);

  if (categoryId && formattedAddress) setDisabled(false);

  return (
    <>
      <small className="slgo-light-text">
        <b>Sto cercando</b>
      </small>
      <div className="slgo-input slgo-autocomplete slgo-autocomplete-home slgo-autocomplete-category pt-1">
        <Autocomplete
          getItemValue={(item) => item.label}
          items={filteredCategories}
          inputProps={{
            id: "categoryName",
            name: "categoryName",
            className: "form-control",
            placeholder: "        Nome della prestazione",
          }}
          value={inputValue}
          onChange={handleInputChange}
          onSelect={handleSelect}
          renderItem={(item) => (
            <div
            className="slgo-autocomplete-dropdown-item"
              key={item.value}
            >
              {item.label}
            </div>
          )}
        />
      </div>
    </>
  );
};

export default CategoryInput;
